/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the Icon component.
 *
 * @param onClick
 */

const NextArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      pos="absolute"
      top={{
        base: 'calc(50% - 19px)',
        sm: 'calc(50% - 19px)',
        md: '40%',
        lg: '40%',
        xl: '40%',
      }}
      right={{ base: '-0', sm: "-10px", md: '-29px' }}
      cursor="pointer"

    // display={{ base: 'none', md: 'block' }}
    >
      <Flex
        w={{ base: 37, md: 62 }}
        height={{ base: 37, md: 62 }}
        rounded="full"
        _hover={{
          boxShadow: "0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03), 0px 38.27199935913086px 26.079999923706055px 0px rgba(0, 0, 0, 0.04), 0px 65px 50px 0px rgba(0, 0, 0, 0.06)"
        }}
        boxShadow="0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03)"
        background="#fff"
        alignItems="center"
        justifyContent="center"
      >
        <Box d={{ base: "none", md: "block" }} pt={{ base: "1px", md: "0" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="25"
            viewBox="0 0 11 18"
            fill="none"
          >
            <path
              d="M1.5 14.1436L8 7.64355L1.5 1.14355"
              stroke="#28231E"
              stroke-width="3"
            />
          </svg>
        </Box>

        <Box d={{ base: "block", md: "none" }} pt={{ base: "3px", md: "0" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
          >
            <path
              d="M1.5 14.1436L8 7.64355L1.5 1.14355"
              stroke="#28231E"
              stroke-width="3"
            />
          </svg>
        </Box>

      </Flex>
    </Box>
  );
};
const PrevArrow = ({ onClick }) => {
  return (
    <Box
      height="100%"
      onClick={onClick}
      pos="absolute"
      top={{
        base: 'calc(50% - 19px)',
        sm: 'calc(50% - 19px)',
        md: '40%',
        lg: '40%',
        xl: '40%',
      }}
      left={{ base: '-13px', sm: "-5px", md: '-29px' }}
      zIndex={1}
      cursor="pointer"

    // display={{ base: 'none', md: 'flex' }}
    >
      <Flex
        w={{ base: 37, md: 62 }}
        height={{ base: 37, md: 62 }}
        rounded="full"
        _hover={{
          boxShadow: "0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03), 0px 38.27199935913086px 26.079999923706055px 0px rgba(0, 0, 0, 0.04), 0px 65px 50px 0px rgba(0, 0, 0, 0.06)"
        }}
        boxShadow="0px 1.7680000066757202px 2.319999933242798px 0px rgba(0, 0, 0, 0.02), 0px 7.9039998054504395px 5.360000133514404px 0px rgba(0, 0, 0, 0.03), 0px 19.6560001373291px 12.239999771118164px 0px rgba(0, 0, 0, 0.03)"
        background="#FFF"
        alignItems="center"
        justifyContent="center"
      >
        <Box d={{ base: "block", md: "none" }} pt={{ base: "1px", md: "0" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
          >
            <path
              d="M9.5 3.14355L3 9.64355L9.5 16.1436"
              stroke="#28231E"
              stroke-width="3"
            />
          </svg>
        </Box>
        <Box d={{ base: "none", md: "block" }} pt="4px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="25"
            viewBox="0 0 11 18"
            fill="none"
          >
            <path
              d="M9.5 3.14355L3 9.64355L9.5 16.1436"
              stroke="#28231E"
              stroke-width="3"
            />
          </svg>
        </Box>

      </Flex>
    </Box>
  );
};
export { NextArrow, PrevArrow };

