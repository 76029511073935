/* Built In Imports */
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

/* External Imports*/
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

/* Internal Imports */
import { handleArray } from '@components/Utility/utils';
import config from '@config';
import { fetchApiAndCache } from '@services/othersService';
import { orderByDistance } from 'geolib';

/**
 *
 * @param {object} children
 * @returns
 */

const ProgramContext = createContext();
// to store  values for text search suggestions
let suggestionValues = [];
let count = 0;
let splProgs = [];
const apiTakingLongerMessage =
  'The page is taking longer than usual to load due to a technical issue. Please check back later or email wms@ishafoundation.org .';

const ProgramFinderStore = ({ children }) => {
  const [storedPrograms, setStoredPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [showFree, setShowFree] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [finalCards, setFinalCards] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [filterObj, setFilterObj] = useState(null);

  const [filters, setFilters] = useState(null);
  const [filtersCount, setFiltersCount] = useState(null);
  const [isFreeTextSearch, setIsFreeTextSearch] = useState(false);
  const [getCount, setGetCount] = useState(false);

  const [filterObjCopy, setFilterObjCopy] = useState(null);

  const [urlParams, setUrlParams] = useState(null);
  const [error, setError] = useState(null);

  const router = useRouter();

  const { location, lang, date, online, programType, freeTextSearch, free } =
    router.query;
  // console.log(router.query.lang);

  //for filter count
  let programTypeCount;
  let locationCount = 0;
  let languageCount;
  let dateCount;
  let onlineCount;
  let showOnlyFreeCount;
  let cityCount;
  let stateCount;
  let centerCount;
  let countryCount;


  const pushIntoDataLayer = (event, obj) => {
    window.dataLayer.push({
      event,
      ...obj,
    });
  };

  // fetches filterObj from api
  const fetchFilterAsideData = async () => {
    let res;
    try {
      res = await fetchApiAndCache(config.SCHEDULE_DATA_API_PATH);
    } catch (error) {
      setError({
        message: apiTakingLongerMessage,
      });
      return;
    }

    const modifiedRes = {
      ...res,
      date: handleArray(res?.date, 'date'),
      onlineInperson: handleArray(res?.onlineInperson, 'onlineInperson'),
      location: {
        ...res.location,
        city: handleArray(res?.location?.city, 'city'),
      },
    };

    setFilterObjCopy(JSON.parse(JSON.stringify(modifiedRes)));

    setFilterObj(JSON.parse(JSON.stringify(modifiedRes)));
  };

  // fetches programs from api
  const fetchData = async () => {
    let res;
    try {
      res = await fetchApiAndCache(config.PROGRAMS_LIST_API_PATH);
    } catch (error) {
      setError({
        message: apiTakingLongerMessage,
      });
      return;
    }

    //default sorting by date asc
    let temp = handleData(res?.results).sort(
      (d1, d2) => new Date(d1.fr).getTime() - new Date(d2.fr).getTime()
    );
    // console.log('temp', temp);
    setStoredPrograms(temp);

    //for initial 12 results without filters
    // setFilteredPrograms(handleData(res.results).slice(0, 12));

    //for initial featured programs
    splProgs = handleData(res?.results).filter(card => {
      return card?.flag?.includes('spl');
    });
    setFilteredPrograms(splProgs);
    count = 12;
  };

  //handles data for program listing api
  const handleData = data => {
    let finalData = [];
    let counter = 0;

    while (data[counter]) {
      let tempData = {
        ...data[counter][counter + 1],
      };
      finalData.push(tempData);
      counter++;
    }

    return finalData;
  };

  // get all items where isChecked is true when collectFilters is false  else make big array for text search
  const getFilters = (
    filterObj,
    collectFilters = false,
    getCardCount = false,
    fromUrl = false
  ) => {
    setGetCount(getCardCount);

    // array in which we will push all the objects which has isChecked true.
    let localFilters = [];
    //added search key in url if selection is from popover

    let keysLev1 = Object.keys(filterObj);

    // removing config key
    keysLev1.splice(keysLev1.indexOf('config'), 1);

    // 1) for programType filters

    let programTypeKeyIndex = keysLev1.indexOf('programType');

    if (programTypeKeyIndex > -1) {
      filterObj[keysLev1[programTypeKeyIndex]].forEach(progCategory => {
        progCategory['subPrograms'] &&
          progCategory['subPrograms'].map((parentProg, idx) => {
            if (parentProg?.isChecked && parentProg?.subPrograms) {
              localFilters.push(parentProg);
            }
            // check if parentProg has childrenProg
            if (parentProg?.subPrograms) {
              let tempArr = parentProg.subPrograms.filter(childProg => {
                childProg.isParentChecked = parentProg?.isChecked;
                childProg.parentId = parentProg?.id;
                if (collectFilters) {
                  let tempObj = {
                    ...childProg,
                    isProgramType: true,
                    name: childProg.programType,
                  };
                  delete tempObj.programType;
                  suggestionValues.push(tempObj);
                }

                // filter childProgs which have isChecked true
                return childProg?.isChecked === true;
              });

              // console.log('programTypeKeyIndex', parentProg?.subPrograms, tempArr, collectFilters);
              if (tempArr.length) {
                localFilters.push(...tempArr);
              }
            } else {
              let tempObj = {
                ...parentProg,
                isProgramType: true,
                name: parentProg.programType,
              };

              delete tempObj.programType;

              if (collectFilters && tempObj) {
                suggestionValues.push(tempObj);
              }

              let tempItem = parentProg.isChecked ? parentProg : null;
              // console.log({ tempItem });
              if (tempItem) {
                localFilters.push(tempItem);
              }
            }
          });
      });
    }

    // 2) for location filters
    let locationKeyIndex = keysLev1.indexOf('location');
    if (locationKeyIndex > -1) {
      Object.keys(filterObj.location).forEach(key => {
        filterObj.location[key].forEach(item => {
          if (collectFilters) {
            let tempObj = {};
            if (item?.city) {
              tempObj = { ...item, isCity: true, name: item?.city };
              delete tempObj.city;
            } else if (item?.centerName) {
              tempObj = { ...item, isCenter: true, name: item.centerName };

              delete tempObj.centerName;
            } 
            else if (item?.state) {
              tempObj = { isState: true, name: Object.keys(item)[0] };
              //tempObj = { ...item, isState: true, name: item?.state };
              //delete tempObj.state;
            }
            else {
              //for country
              tempObj = { isCountry: true, name: Object.keys(item)[0] };
              // console.log(suggestionValues);
            }
            if (tempObj) {
              suggestionValues.push(tempObj);
            }
          }

          if (item?.isChecked) {
            localFilters.push(item);
          }
        });
      });
    }

    // 3) for online filters
    let onlineInpersonKeyIndex = keysLev1.indexOf('onlineInperson');

    if (onlineInpersonKeyIndex > -1) {
      filterObj.onlineInperson.forEach(item => {
        if (item?.isChecked) {
          localFilters.push(item);
        }
      });
    }

    // 4) for date filters
    let dateKeyIndex = keysLev1.indexOf('date');
    if (dateKeyIndex > -1) {
      filterObj.date.forEach(item => {
        if (item?.isChecked) {
          localFilters.push(item);
        }
      });
    }

    // 5) for language filters
    let languageKeyIndex = keysLev1.indexOf('language');
    if (languageKeyIndex > -1) {
      Object.keys(filterObj.language).forEach(key => {
        Object.keys(filterObj.language[key]).forEach(ckey => {
          if (collectFilters) {
            let tempObj = {
              isLanguage: true,
              name: ckey,
              code: filterObj?.language[key][ckey],
            };
            suggestionValues.push(tempObj);
          }
          if (filterObj.language[key][ckey]?.isChecked) {
            localFilters.push(filterObj.language[key][ckey]);
          }
        });
      });
    }

    //6) for show only free programs filter
    let priceKeyIndex = keysLev1.indexOf('price');
    if (priceKeyIndex > -1) {
      if (filterObj.price?.showOnlyFree) {
        showOnlyFreeCount = 1;
        localFilters.push(filterObj.price);
      }
    }

    // freeTExtSearch

    if (filterObj?.freeTextSearch) {
      localFilters.push({ freeTextSearch: filterObj.freeTextSearch });
    }

    //7) for mob scroll if apply button clicked on filter popup
    if (filterObj?.scroll) {
      localFilters.push({ scroll: filterObj?.scroll });
    }

    //8) for accordion
    if (filterObj?.activeFilter) {
      localFilters.push({
        activeFilter: filterObj?.activeFilter,
      });
    }

    // 9) for leftUI city box
    if (filterObj?.leftCityBox) {
      localFilters.push({ leftCityBox: filterObj?.leftCityBox });
    }

    // 10) for popup
    if (filterObj?.popup == 1) {
      localFilters.push({ popup: 1 });
    }
    // 11)  for leftUI country box

    if (filterObj?.leftCountryBox) {
      localFilters.push({ leftCountryBox: filterObj?.leftCountryBox });
    }
    // 12) for leftUI state box

    if (filterObj?.leftStateBox) {
      localFilters.push({ leftStateBox: filterObj?.leftStateBox });
    }

    if (filterObj?.tag) {
      localFilters.push({ search: filterObj?.tag });
    }

    suggestionValues = uniqBy(suggestionValues, obj => obj.name);

    // sorting suggestionArray
    if (collectFilters) {
      suggestionValues.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name == b.name) {
          return 0;
        } else {
          return -1;
        }
      });
    }

    // console.log('collectFilters', collectFilters);
    if (!collectFilters) {
      // setting finalCards for rendering

      const finalCards = getFinalCards(localFilters, storedPrograms);

      let count = {
        programType: programTypeCount,
        location: locationCount > 0 ? locationCount : null,
        language: languageCount,
        date: dateCount,
        onlineInperson: onlineCount,
        showOnlyFree: showOnlyFreeCount,
        city: cityCount,
        state: stateCount,
        freeTextSearch: isFreeTextSearch ? 1 : null,
        country: countryCount,
        center: centerCount,
      };
      if (!fromUrl) {
        console.log('localFilters', localFilters);
        setFilters(localFilters);
      }
      if (finalCards) {
        if (getCardCount) {
          setFiltersCount(count);
          return finalCards.length;
        }
        setFilteredPrograms([]);
        // setFinalCards(finalCards);
        return { finalCards, count };
      }
    }
  };

  // prepare finalCards based on filters
  const getFinalCards = (filters, cards) => {
    let finalResults = cards ? cards : storedPrograms;

    // on free search apply filter from finalCards

    // 1) filtering for programType

    let filterArr = filters.filter(item => {
      return item?.programType;
    });

    if (filterArr?.length) {
      programTypeCount = filterArr.length;
      let temp = [];
      finalResults.forEach(card => {
        filterArr.forEach(item => {
          if (item?.id == card?.type) {
            temp.push(card);
          }
        });
      });
      finalResults = temp;
    }

    // 2) filtering for online

    filterArr = filters.filter(item => {
      return item?.onlineInperson;
    });

    if (filterArr?.length) {
      onlineCount = filterArr.length;
      let temp = [];
      finalResults.forEach(card => {
        filterArr.forEach(item => {
          let tempFlag = card?.flag?.includes('inperson')
            ? 'inPersonPrograms'
            : 'onlinePrograms';
          if (item?.onlineInperson == tempFlag) {
            temp.push(card);
          }
        });
      });
      finalResults = temp;
    }

    // 3) filtering for city

    // array for combination of city and center
    let arr = [];

    filterArr = filters.filter(item => {
      return item?.city || item?.state || item?.country;
    });

    if (filterArr?.length) {
      arr.push(...filterArr);
      locationCount += filterArr.length;
    }

    // Uncomment code from 446 to 464 if you want country filter separate and delete line of code 486 to 488
    // 4) filtering for country

    // filterArr = filters.filter(item => {
    //   return item?.country;
    // });

    // if (filterArr?.length) {
    //   locationCount += filterArr.length;
    //   let temp = [];
    //   finalResults.forEach(card => {
    //     filterArr.forEach(item => {
    //       if (item?.country?.toLowerCase() == card?.cntry?.toLowerCase()) {
    //         temp.push(card);
    //       }
    //     });
    //   });

    //   finalResults = temp;
    // }

    // 5) filtering for center
    filterArr = filters.filter(item => {
      return item?.centerName;
    });
    if (filterArr?.length) {
      arr.push(...filterArr);
      locationCount += filterArr.length;
    }

    // combining center and city cards
    if (arr?.length) {
      let temp = [];
      finalResults.forEach(card => {
        arr.forEach(item => {
          if (
            card?.flag.includes(',') &&
            item?.centerID?.toLowerCase() ==
              card?.flag?.toLowerCase().split(',')[1]
          ) {
            temp.push(card);
          } else if (
            item?.country?.toLowerCase() == card?.cntry?.toLowerCase()
          ) {
            temp.push(card);
          } 
          else if ( card?.state &&
            item?.state?.toLowerCase() == card?.state?.toLowerCase()
          ) {
            temp.push(card);
          }
          else if (
            card?.city &&
            item?.city?.toLowerCase() == card?.city?.toLowerCase()
          ) {
            temp.push(card);
          }
        });
      });

      // console.log({ temp });
      finalResults = [...new Set(temp)];
    }

    // 6) filtering for language

    filterArr = filters.filter(item => {
      return item?.lang;
    });
    if (filterArr?.length) {
      languageCount = filterArr.length;
      let temp = [];
      finalResults.forEach(card => {
        filterArr.forEach(item => {
          if (card?.lang?.toLowerCase()?.includes(item?.lang?.toLowerCase())) {
            temp.push(card);
          }
        });
      });
      finalResults = [...new Set(temp)];
    }

    // 7) filtering for free text search

    filterArr = filters.filter(item => {
      return item?.freeTextSearch;
    });

    if (filterArr?.length) {
      // clear applied filters and count
      // setFilters(null);
      setFiltersCount({ freeTextSearch: 1 });
      setIsFreeTextSearch(true);

      filterArr.forEach(item => {
        finalResults = finalResults.filter(card => {
          const conditions =
            card?.name
              ?.toLowerCase()
              ?.includes(item?.freeTextSearch?.toLowerCase()) ||
            card?.city
              ?.toLowerCase()
              ?.includes(item?.freeTextSearch?.toLowerCase()) ||
            card?.place
              ?.toLowerCase()
              ?.includes(item?.freeTextSearch?.toLowerCase()) ||
            card?.cntry
              ?.toLowerCase()
              ?.includes(item?.freeTextSearch?.toLowerCase()) ||
            card?.lang
              ?.toLowerCase()
              ?.includes(item?.freeTextSearch?.toLowerCase());
          // console.log({ conditions, item });
          return conditions;
        });
      });
    }

    // 8) filtering for free programs

    filterArr = filters.filter(item => {
      if (item?.showOnlyFree) {
        return item.isChecked;
      }
      return false;
    });

    if (filterArr?.length) {
      finalResults = finalResults.filter(card => {
        return card?.flag?.includes('free');
      });
    }

    // 9) filtering for date

    filterArr = filters.filter(item => {
      // console.log(item);
      return item?.date;
    });

    if (filterArr?.length) {
      dateCount = filterArr.length;
      let today = moment();
      let tempArr = [];

      filterArr.forEach(item => {
        if (item?.date == 'next1Month') {
          let temp = finalResults.filter(card => {
            let futureMonth = moment(today).add(1, 'M');
            let cardFrom = moment(card.fr);
            let diff = futureMonth.diff(cardFrom, 'days');
            return diff > -1 && diff <= 30;
          });

          if (temp?.length) {
            tempArr.push(...temp);
          }
        } else if (item?.date == 'next3Months') {
          let temp = finalResults.filter(card => {
            let cardFrom = moment(card.fr);
            let diff = cardFrom.diff(today, 'months');
            return (
              diff >= 0 &&
              diff <= 3 &&
              today.month() != cardFrom.month() &&
              cardFrom.month() - today.month() != 4
            );
          });
          if (temp?.length) {
            tempArr.push(...temp);
          }
        } else if (item?.date == 'thisMonth') {
          let temp = finalResults.filter(card => {
            let cardFrom = moment(card.fr);
            let diff = cardFrom.diff(today, 'months');
            return diff == 0 && today.month() == cardFrom.month();
          });
          if (temp?.length) {
            tempArr.push(...temp);
          }
        } else if (item?.date == 'thisWeek') {
          let temp = finalResults.filter(card => {
            let cardFrom = moment(card.fr);
            let diff = cardFrom.diff(today, 'weeks');
            let diff2 = cardFrom.week() - today.week();

            return diff == 0 && diff2 == 0;
          });
          if (temp?.length) {
            tempArr.push(...temp);
          }
        }
      });
      // console.log(tempArr);

      // removing common items
      tempArr = [...new Set(tempArr)];
      finalResults = tempArr;
    }

    // sort by date asc
    finalResults.sort(
      (d1, d2) => new Date(d1.fr).getTime() - new Date(d2.fr).getTime()
    );
    finalResults.forEach(pro => {
      const [latitude, longitude] = pro?.latlong?.split(',');
      pro.latitude = latitude;
      pro.longitude = longitude;
    });
    if (!userLocation?.browser && userLocation?.vpn) {
      finalResults = orderByDistance({ ...userLocation?.vpn }, finalResults);
      // console.log(filteredPrograms, 'cards', sortedPrograms);
    } else if (userLocation?.browser) {
      finalResults = orderByDistance(
        { ...userLocation?.browser },
        finalResults
      );
    } else {
      finalResults = finalResults;
    }
    // moving ieoProgram to top if it is in finalCards
    const ieoProgram = finalResults.findIndex(obj => obj.id === '12733');
    if (ieoProgram > -1) {
      let tempObj = finalResults[ieoProgram];
      finalResults.splice(ieoProgram, 1);
      finalResults.splice(0, 0, tempObj);
    }

    return finalResults;
  };

  const fetchFilteredData = (size = 12) => {
    // console.log({ tempIndx });
    if (finalCards) {
      let tempIndx =
        finalCards?.length > filteredPrograms?.length
          ? count + size
          : finalCards?.length - filteredPrograms.length;
      const slicedPrograms = finalCards.slice(count, tempIndx);
      setFilteredPrograms(filteredPrograms.concat(slicedPrograms));
    } else {
      // console.log(count, count + 12);

      const slicedPrograms = splProgs.slice(count, count + 12);
      setFilteredPrograms(filteredPrograms.concat(slicedPrograms));
    }
    count += size;
  };

  const clearFilters = () => {
    setFilterObjCopy(JSON.parse(JSON.stringify(filterObj)));
    setFiltersCount(null);
    setFilters(null);
    setFilteredPrograms([]);
    setIsFreeTextSearch(false);

    router.push(
      `/${router.query?.region}/${router.query?.language}/program-finder`,
      undefined,
      { scroll: false }
    );
  };

  const handleUrlStructure = () => {
    // console.log('handleUrlStructure running');
    let tempObj = {
      programType: [],
      location: [],
      date: [],
      lang: [],
      onlineInperson: [],
      search: [],
      freeTextSearch: [],
      showOnlyFree: [],
      scroll: [],
      activeFilter: [],
      leftCityBox: [],
      leftStateBox: [],
      popup: 0,
      leftCountryBox: [],
    };

    filters?.forEach(filter => {
      if (filter?.programType) {
        tempObj.programType.push(filter.programType);
      } else if (filter?.onlineInperson) {
        let tempVal = filter.onlineInperson == 'onlinePrograms' ? 'yes' : 'no';
        tempObj.onlineInperson.push(tempVal);
      } else if (filter?.date) {
        tempObj.date.push(filter.date);
      } else if (filter?.lang) {
        tempObj.lang.push(filter.lang);
      } else if (filter?.search) {
        tempObj.search.push(filter.search.name);
      } else if (filter?.freeTextSearch) {
        tempObj.freeTextSearch.push(filter.freeTextSearch);
      } else if (filter?.showOnlyFree) {
        tempObj.showOnlyFree.push('yes');
      } else if (filter?.scroll) {
        tempObj.scroll.push(filter.scroll);
        delete filterObjCopy.scroll;
      } else if (filter?.activeFilter) {
        tempObj.activeFilter.push(filter.activeFilter);
        delete filterObjCopy.activeFilter;
      } else if (filter?.leftCityBox) {
        tempObj.leftCityBox.push(filter.leftCityBox);
        delete filterObjCopy.leftCityBox;
      } else if (filter?.popup) {
        tempObj.popup = 1;
        delete filterObjCopy.popup;
      } 
      else if (filter?.leftStateBox) {
        tempObj.leftStateBox.push(filter.leftStateBox);
        delete filterObjCopy.leftStateBox;
      }
      else if (filter?.leftCountryBox) {
        tempObj.leftCountryBox.push(filter.leftCountryBox);
        delete filterObjCopy.leftCountryBox;
      } else {
        tempObj.location.push(
          filter?.country
            ? Object.keys(filter)[0]
            : filter?.city
            ? filter.city 
            : filter?.state
            ? Object.keys(filter)[0]
            : filter?.centerName
        );
      }
    });

    setUrlParams(tempObj);
  };

  const applyFiltersViaUrl = () => {
    // console.log('applyFiltersViaUrl running');
    // console.log('filterObjCopy before:', filterObjCopy);
    let temp = [];

    const pushParamsToArray = (param, objKey) => {
      if (param) {
        if (typeof param == 'string') {
          temp.push({ [objKey]: param });
        } else {
          param?.forEach(item => {
            temp.push({ [objKey]: item });
          });
        }
      }
    };

    pushParamsToArray(online, 'onlineInperson');
    pushParamsToArray(lang, 'language');
    pushParamsToArray(date, 'date');
    pushParamsToArray(location, 'location');
    pushParamsToArray(programType, 'programType');

    if (free == 'yes') {
      pushParamsToArray(free, 'showOnlyFree');
    }
    if (freeTextSearch) {
      if (filterObjCopy) {
        filterObjCopy.freeTextSearch = freeTextSearch;
        temp.push({ freeTextSearch });
        setIsFreeTextSearch(true);
      }
    }

    if (temp?.length == 0) {
      setFiltersCount(null);
      setFilteredPrograms([]);
      setFinalCards(splProgs);
      return;
    }

    //sync with left side
    temp?.forEach(obj => {
      if (obj?.date) {
        //date
        filterObjCopy?.date?.forEach(item => {
          if (item?.date?.toLowerCase() == obj.date?.toLowerCase()) {
            item.isChecked = true;
          }
        });
      } else if (obj?.language) {
        //language
        Object.keys(filterObjCopy?.language)?.forEach(key => {
          Object.keys(filterObjCopy.language[key]).forEach(ckey => {
            // console.log({ ckey });
            // console.log(
            //   obj.language,
            //   filterObjCopy.language[key][ckey],
            //   obj.language == filterObjCopy.language[key][ckey]
            // );
            if (
              typeof filterObjCopy.language[key][ckey] == 'string' &&
              obj.language == filterObjCopy.language[key][ckey]
            ) {
              filterObjCopy.language[key][ckey] = {
                [ckey]: filterObjCopy.language[key][ckey],
                lang: obj.language,
                isChecked: true,
              };
            }
          });
        });
      } else if (obj?.onlineInperson) {
        let tempVal =
          obj.onlineInperson == 'yes' ? 'onlinePrograms' : 'inpersonPrograms';
        //onlineInperson
        filterObjCopy?.onlineInperson?.forEach(item => {
          if (tempVal?.toLowerCase() == item?.onlineInperson?.toLowerCase()) {
            item.isChecked = true;
          }
        });
      } else if (obj?.programType) {
        //programType
        filterObjCopy?.programType?.forEach(progCategory => {
          progCategory?.subPrograms?.forEach(item => {
            if (
              obj?.programType?.toLowerCase() == item.programType?.toLowerCase()
            ) {
              item.isChecked = true;
            }

            if (!item?.subPrograms) {
              if (
                obj?.programType?.toLowerCase() ==
                item.programType?.toLowerCase()
              ) {
                item.isChecked = true;
              }
            }
            item?.subPrograms?.forEach(prog => {
              if (
                obj?.programType?.toLowerCase() ==
                prog.programType?.toLowerCase()
              ) {
                prog.isChecked = true;
              }
            });
          });
        });
      } else if (obj?.location) {
        //city
        filterObjCopy?.location?.city?.forEach(city => {
          if (
            city?.city?.toLowerCase() ==
            obj.location?.replace('+', ' ')?.toLowerCase()
          ) {
            city.isChecked = true;
          }
        });
        //state
        filterObjCopy?.location?.state?.forEach(state => {
          if (
            Object.keys(state)[0].toLowerCase() ==
            obj.location?.replace('+', ' ')?.toLowerCase()
          ) {
            state.isChecked = true;
            state.state = state[Object.keys(state)[0]];
          }
        });
        //center
        filterObjCopy?.location?.center?.forEach(center => {
          if (
            center?.centerName?.toLowerCase() ==
            obj.location?.replace('+', ' ')?.toLowerCase()
          ) {
            center.isChecked = true;
          }
        });
        //country
        filterObjCopy?.location?.country?.forEach(country => {
          if (
            Object.keys(country)[0].toLowerCase() ==
            obj.location?.replace('+', ' ')?.toLowerCase()
          ) {
            country.isChecked = true;
            country.country = country[Object.keys(country)[0]];
          }
        });
      } else if (obj?.showOnlyFree == 'yes') {
        filterObjCopy.price = {
          showOnlyFree: 'Show free programs',
          isChecked: true,
        };
      }
    });

    // console.log(
    //   'filterObjCopy changed after applyFiltersViaUrl:',
    //   filterObjCopy
    // );

    const { finalCards, count } = getFilters(
      filterObjCopy,
      false,
      false,
      false
    );

    if (finalCards && count) {
      // console.log('filters applied through url');

      setFiltersCount(count);
      setFinalCards(finalCards);
    } else {
      // console.log('filters applied through url');

      setFinalCards([]);
      setFiltersCount(null);
    }
  };

  const isFiltersApplied = useMemo(() => {
    return (
      filtersCount?.programType ||
      filtersCount?.location ||
      filtersCount?.language ||
      filtersCount?.date ||
      filtersCount?.onlineInperson ||
      filtersCount?.showOnlyFree ||
      filtersCount?.freeTextSearch
    );
  }, [filtersCount]);

  useEffect(() => {
    if (!getCount && filters) {
      // console.log(
      //   'localFilters changed now it is time for handleUrlStructure',
      //   {
      //     filters,
      //   }
      // );
      handleUrlStructure();
    }
  }, [filters]);

  useEffect(() => {
    if (urlParams) {
      // console.log('url params changed', { urlParams });
      let pathname = router.asPath.split('?')[0];

      // console.log({ pathname });
      router.push(
        {
          pathname,
          query: {
            programType: urlParams.programType,
            location: urlParams.location,
            date: urlParams.date,
            online: urlParams.onlineInperson,
            lang: urlParams.lang,
            search: urlParams.search,
            freeTextSearch: urlParams.freeTextSearch,
            free: urlParams.showOnlyFree,
            activeFilter: urlParams.activeFilter,
            scroll: urlParams.scroll,
            leftCityBox: urlParams.leftCityBox,
            leftStateBox: urlParams.leftStateBox,
            popup: urlParams.popup,
            leftCountryBox: urlParams.leftCountryBox,
          },
        },
        undefined,
        { scroll: false }
      );
      // console.log('route changed and time to apply filters');
    }
  }, [urlParams]);

  useEffect(() => {
    // console.log('api calling..');

    fetchData();
    fetchFilterAsideData();
  }, [router.query.region]);

  useEffect(() => {
    if (filterObj && storedPrograms?.length && filterObjCopy) {
      setIsLoading(false);

      // make array for suggestion
      getFilters(filterObj, true);
      // console.log('loading is set to false ');
      if (
        programType ||
        date ||
        location ||
        lang ||
        freeTextSearch ||
        free ||
        online
      ) {
        // console.log('about to start applyFiltersViaUrl first time');
        applyFiltersViaUrl();
      }
    }
  }, [filterObj, storedPrograms]);

  useEffect(() => {
    count = 0;
    fetchFilteredData();
    // console.log('finalCards changed', { finalCards });
  }, [finalCards]);

  useEffect(() => {
    if (storedPrograms?.length && router.isReady) {
      applyFiltersViaUrl();
    }
  }, [router.asPath, router.isReady]);

  return (
    <ProgramContext.Provider
      value={{
        storedPrograms,
        filteredPrograms,
        isLoading,
        isFreeTextSearch,
        suggestionValues,
        finalCards,
        filtersCount,
        filters,
        filterObj,
        filterObjCopy,
        isFiltersApplied,
        error,
        showFree,
        userLocation,
        setUserLocation,
        setShowFree,
        setFilteredPrograms,
        fetchFilteredData,
        getFilters,
        getFinalCards,
        setFinalCards,
        setFilters,
        setFiltersCount,
        setFilterObjCopy,
        setIsFreeTextSearch,
        clearFilters,
        pushIntoDataLayer,
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgramContext = () => {
  return useContext(ProgramContext);
};

export default ProgramFinderStore;
