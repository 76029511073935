/* BuiltIn Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* hooks */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useProgramContext } from '@components/UI/ProgramFinder/ProgramFinderStore';
import { useDebounce } from '@hooks/useDebounce';

/* Services */

/**
 *
 * @param {object} data
 * @param getMobileFilter
 * @param mobFilterActive
 * @param setMobFilterActive
 * @returns
 */
const FilterAside = ({
  data,
  getMobileFilter,
  mobFilterActive,
  setMobFilterActive,
  setSearch,
  tag,
  setTag,
  // onLocation,
  // userLocation,
}) => {
  const {
    getFilters,
    clearFilters,
    filterObjCopy,
    filtersCount,
    filters,
    isFiltersApplied,
    setFilterObjCopy,
    isFreeTextSearch,
    filterObj,
    setFiltersCount,
    pushIntoDataLayer,
    showFree,
    setShowFree,
  } = useProgramContext();
  const router = useRouter();
  const [obj, setObj] = useState(null);
  useEffect(() => {
    if (filters) {
      [...filters]?.map(el => {
        if (el?.activeFilter) {
          setObj(el || obj);
        }
      });
    }
  }, [router?.query?.activeFilter]);
  const isMobile = useMediaQuery({ maxWidth: '767px' });

  const [filterActive, setFilterActive] = useState(
    isMobile
      ? mobFilterActive
      : Number((obj?.activeFilter / 100 - 1).toFixed()) || 0
  );
  const [cardCount, setCardCount] = useState(null);

  // selection made from filtered cities
  const [selectedCity, setSelectedCity] = useState(null);
  const [citySearchBox, setCitySearchBox] = useState(
    router.query?.leftCityBox || ''
  );
  let debouncedValue = useDebounce(citySearchBox, 300);

  // state
  const [selectedState, setSelectedState] = useState(null);
  const [stateSearchBox, setStateSearchBox] = useState(
    router.query?.leftStateBox || ''
  );
  let debouncedStateValue = useDebounce(stateSearchBox, 300);

  // Country
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countrySearchBox, setCountrySearchBox] = useState(
    router.query?.leftCountryBox || ''
  );
  let debouncedCountryValue = useDebounce(countrySearchBox, 300);

  const handleLocationChange = (e, child = null, parentArray = null, indx) => {
    let { checked, name } = e.target;
    passCurrentAccordion(indx, 'location');

    // console.log({ child, parentArray, name, checked });

    //********************** */ for city

    if (name == 'city') {
      //uncheck all countries
      // uncheckAllChildren('location', 'country');

      // removeTagOnCondition(tag?.isCountry || tag?.isCity);

      // preventing multiple city selection

      let tempArr = parentArray.filter(item => {
        return item?.isChecked;
      });
      if (tempArr?.length) {
        // uncheck all cities
        // uncheckAllChildren('location', 'city');
        // parentArray.forEach(item => {
        //   item.isChecked = false;
        // });
      }

      // check current city
      if (e.key == 'Enter') {
        let indx = parentArray.indexOf(child);
        parentArray[indx] = {
          ...child,
          isChecked: true,
        };

        // setSuggestions(null);
        // setCitySearchBox('');
      } else {
        let indx = parentArray.indexOf(child);
        parentArray[indx] = {
          ...child,
          isChecked: checked,
        };
        if (checked) {
          pushIntoDataLayer('pf_location_selection', { location: child?.city });
        }
      }
    } else if (name == 'state') {
      //********************** */ for state
      let i = parentArray.indexOf(child);
      if (checked) {
        pushIntoDataLayer('pf_location_selection', {
          location: Object.keys(child)[0],
        });
      }

      // check current state
      parentArray[i] = {
        ...child,
        state: child[Object.keys(child)[0]],
        isChecked: checked,
      };
    } else {
      //********************** */ for center or country

      if (typeof checked === 'boolean' && name != 'city') {
        let i = parentArray.indexOf(child);

        if (name == 'country') {
          // removeTagOnCondition(tag?.isCity || tag?.isCenter);

          if (checked) {
            // uncheck all cities on single country check
            // uncheckAllChildren('location', 'city');
            // uncheck all country
            // uncheckAllChildren('location', 'country');
            //uncheck all centers
            // uncheckAllChildren('location', 'center');

            pushIntoDataLayer('pf_location_selection', {
              location: Object.keys(child)[0],
            });
          }

          // check current country
          parentArray[i] = {
            ...child,
            country: child[Object.keys(child)[0]],
            isChecked: checked,
          };
        } else {
          // remove tag from left side
          // console.log(child, tag);
          // removeTagOnCondition(
          //   child?.centerName == tag?.name || tag?.isCountry
          // );

          if (checked) {
            //uncheck all country on a center check
            // uncheckAllChildren('location', 'country');
            pushIntoDataLayer('pf_location_selection', {
              location: child.centerName,
            });
          }
          parentArray[i] = {
            ...child,
            isChecked: checked,
          };
        }
      }
    }

    //send filters to context

    if (isMobile) {
      // filterObjCopy.popup = 1;
      let count = getFilters(filterObjCopy, false, true);
      setCardCount(count);
      return;
    }
    getFilters(filterObjCopy);
  };

  const handleProgramTypeChange = (
    e,
    isParent = false,
    parent = null,
    child = null,
    indx = null
  ) => {
    const { checked } = e.target;

    // console.log({ indx });
    passCurrentAccordion(indx, 'programType');

    // console.log({ isParent, parent, child });
    if (isParent) {
      //************************/ if event is from parent.

      parent.isChecked = checked;

      parent['subPrograms']?.forEach(subProg => {
        if (checked) {
          pushIntoDataLayer('pf_program_selection', {
            location: subProg.programType,
          });
        }
        subProg.isChecked = checked;
        removeTagOnCondition(subProg?.id == tag?.id && !checked);
      });
    } else {
      //********************** if event is from child programs.
      removeTagOnCondition(child?.id == tag?.id && !checked);

      child.isChecked = checked;
      if (checked) {
        pushIntoDataLayer('pf_program_selection', {
          location: child.programType,
        });
      }

      if (!checked) {
        //uncheck parent on single child uncheck
        if (parent && typeof parent == 'object' && !Array.isArray(parent)) {
          parent.isChecked = checked;
        }
      } else {
        //check parent on all child check
        {
          let arr = parent['subPrograms']?.filter(c => {
            return c?.isChecked;
          });
          if (arr?.length == parent['subPrograms']?.length) {
            parent.isChecked = true;
          }
        }
      }
    }

    //send filters to context
    if (isMobile) {
      // filterObjCopy.popup = 1;
      let count = getFilters(filterObjCopy, false, true);
      setCardCount(count);
      return;
    }

    getFilters(filterObjCopy);
  };

  const handleLanguageChange = (e, child = null, parentObj = null) => {
    const { checked } = e.target;
    passCurrentAccordion(0, 'language');

    // console.log({
    //   isParent,
    //   isChecked: checked,
    //   child,
    //   parentObj,
    // });

    if (checked) {
      pushIntoDataLayer('pf_language_selection', {
        location: parentObj[child],
      });
    }

    if (parentObj[child]?.lang) {
      parentObj[child] = { ...parentObj[child], isChecked: checked };
    } else {
      parentObj[child] = {
        lang: parentObj[child],
        [child]: parentObj[child],
        isChecked: checked,
      };
    }
    // console.log(parentObj[child]);
    removeTagOnCondition(child == tag?.name && !checked);

    // console.log({ filterObjCopy });

    //send filters to context
    if (isMobile) {
      // filterObjCopy.popup = 1;
      let count = getFilters(filterObjCopy, false, true);
      setCardCount(count);

      return;
    }

    getFilters(filterObjCopy);
    // console.log({ isParent, child });
  };

  const handleOnlineInPersonAndDate = (e, child = null, parentArray = null) => {
    const { checked } = e.target;
    // console.log(parentArray);

    if (parentArray[0]?.onlineInperson) {
      if (checked) {
        pushIntoDataLayer('pf_program_mode_selection', {
          program_mode: child?.onlineInperson,
        });
      }
      passCurrentAccordion(0, 'onlineInperson');
    } else {
      passCurrentAccordion(0, 'date');
    }

    let indx = parentArray.indexOf(child);
    parentArray[indx] = {
      ...child,
      isChecked: checked,
    };

    //send filters to context
    if (isMobile) {
      let count = getFilters(filterObjCopy, false, true);
      setCardCount(count);
      return;
    }

    getFilters(filterObjCopy);
  };

  const handleCitySearch = e => {
    const { value } = e.target;
    setCitySearchBox(value);
  };

  const handleStateSearch = e => {
    const { value } = e.target;
    setStateSearchBox(value);
  };

  const handleCountrySearch = e => {
    const { value } = e.target;
    setCountrySearchBox(value);
  };
  const handleLeftClear = () => {
    // clearFilters();
    // console.log('first');
    if (isFreeTextSearch) {
      setFilterObjCopy(JSON.parse(JSON.stringify(filterObj)));
      let newObj = {
        ...filterObj,
        freeTextSearch: router.query?.freeTextSearch,
        popup: 1,
      };
      getFilters(newObj);
    } else {
      // clearFilters();

      if (
        router.query?.programType ||
        router.query?.free ||
        router.query?.location ||
        router.query?.lang ||
        router.query?.online ||
        router.query?.date
      ) {
        let newObj = {
          ...filterObj,
          popup: 1,
        };
        setFilterObjCopy(JSON.parse(JSON.stringify(filterObj)));
        getFilters(newObj);
        return;
      }
      setFilterObjCopy(JSON.parse(JSON.stringify(filterObj)));
      setFiltersCount(null);
      setCardCount(null);
    }
  };

  const removeTagOnCondition = cond => {
    if (cond) {
      delete filterObjCopy?.tag;
    }
  };

  const uncheckAllChildren = (pKey, cKey) => {
    filterObjCopy[pKey][cKey]?.forEach(item => {
      item.isChecked = false;
    });
  };

  const passCurrentAccordion = (indx, type) => {
    if (!isNaN(parseInt(indx))) {
      filterObjCopy.activeFilter =
        Object.keys(filterObjCopy).indexOf(type) * 100 + 100 + indx;
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(mobFilterActive))) {
      setFilterActive(mobFilterActive);
      getMobileFilter(true);
    } else {
      getMobileFilter(false);
    }
  }, [mobFilterActive]);

  useEffect(() => {
    if (
      router.isReady &&
      router.query?.scroll == 'true' &&
      isMobile &&
      window
    ) {
      window.scrollTo(0, 300);
    }

    if (
      router.isReady &&
      !isNaN(parseInt(router.query.activeFilter)) &&
      !isMobile
    ) {
      let no = parseInt(router.query.activeFilter);
      if (no > 99 && no < 901) {
        setFilterActive(Math.floor(no / 100) - 1);
      }
    }

    if (router.isReady && router.query?.leftCityBox) {
      setSelectedCity(router.query?.leftCityBox);
      setCitySearchBox(router.query?.leftCityBox);
    }
  }, [router.isReady]);

  function handleFree() {
    if (!showFree) {
      filterObjCopy.activeFilter = 'none';
      filterObjCopy.price = {
        showOnlyFree: 'Show free programs',
        isChecked: true,
      };
      setShowFree(true);
      window.dataLayer.push({
        event: 'pf_show_free',
      });
      // console.log(active, 'active', router, filterObjCopy, 'filterobj copy');
    } else if (filterObjCopy?.price) {
      setShowFree(false);
      delete filterObjCopy.price;
      router.query.free = '';
    }

    //send to context
    getFilters(filterObjCopy);
  }
  // console.log(filterActive, 'filterActive');
  // const getPrograms = useCallback(
  //   async (collectFilters = true) => {
  //     const localFilters = [];
  //     filterObjCopy['programType'].forEach(progCategory => {
  //       progCategory['subPrograms'] &&
  //         progCategory['subPrograms'].map((parentProg, idx) => {
  //           if (parentProg?.isChecked && parentProg?.subPrograms) {
  //             localFilters.push({
  //               mainProg: progCategory.programType,
  //               ...parentProg,
  //             });
  //           }
  //           // check if parentProg has childrenProg
  //           if (parentProg?.subPrograms) {
  //             let tempArr = parentProg.subPrograms.filter(childProg => {
  //               childProg.isParentChecked = parentProg?.isChecked;
  //               childProg.parentId = parentProg?.id;
  //               childProg.mainProg = progCategory.programType;
  //               if (collectFilters) {
  //                 let tempObj = {
  //                   ...childProg,
  //                   isProgramType: true,
  //                   name: childProg.programType,
  //                   mainProg: progCategory.programType,
  //                 };
  //                 delete tempObj.programType;
  //                 // suggestionValues.push(tempObj);
  //               }

  //               // filter childProgs which have isChecked true
  //               return childProg?.isChecked === true;
  //             });

  //             // console.log('programTypeKeyIndex', parentProg?.subPrograms, tempArr, collectFilters);
  //             if (tempArr.length) {
  //               localFilters.push(...tempArr);
  //             }
  //           } else {
  //             let tempObj = {
  //               ...parentProg,
  //               isProgramType: true,
  //               name: parentProg.programType,
  //               mainProg: progCategory.programType,
  //             };

  //             delete tempObj.programType;

  //             // if (collectFilters && tempObj) {
  //             //   suggestionValues.push(tempObj);
  //             // }

  //             let tempItem = parentProg.isChecked ? parentProg : null;
  //             // console.log({ tempItem });
  //             if (tempItem) {
  //               localFilters.push(tempItem);
  //             }
  //           }
  //         });
  //     });
  //     return localFilters;
  //   },
  //   [filters]
  // );
  const getPrograms = (collectFilters = true) => {
    const localFilters = [];
    filterObjCopy['programType'].forEach(progCategory => {
      progCategory['subPrograms'] &&
        progCategory['subPrograms'].map((parentProg, idx) => {
          if (parentProg?.isChecked && parentProg?.subPrograms) {
            localFilters.push({
              mainProg: progCategory.programType,
              ...parentProg,
            });
          }
          // check if parentProg has childrenProg
          if (parentProg?.subPrograms) {
            let tempArr = parentProg.subPrograms.filter(childProg => {
              childProg.mainProg = progCategory.programType;
              childProg.isParentChecked = parentProg?.isChecked;
              childProg.parentId = parentProg?.id;
              if (collectFilters) {
                let tempObj = {
                  mainProg: progCategory.programType,
                  isProgramType: true,
                  name: childProg.programType,
                  ...childProg,
                };
                delete tempObj.programType;
                // suggestionValues.push(tempObj);
              }

              // filter childProgs which have isChecked true
              return childProg?.isChecked === true;
            });

            // console.log('programTypeKeyIndex', parentProg?.subPrograms, tempArr, collectFilters);
            if (tempArr.length) {
              localFilters.push(...tempArr);
            }
          } else {
            let tempObj = {
              mainProg: progCategory?.programType,
              isProgramType: true,
              name: parentProg.programType,
              ...parentProg,
            };

            delete tempObj.programType;

            // if (collectFilters && tempObj) {
            //   suggestionValues.push(tempObj);
            // }

            let tempItem = parentProg.isChecked ? parentProg : null;
            // console.log({ tempItem });
            if (tempItem) {
              localFilters.push({
                mainProg: progCategory?.programType,
                ...tempItem,
              });
            }
          }
        });
    });
    return localFilters;
  };
  useEffect(() => {
    getPrograms();
  }, [filters]);
  // console.log(filterObj, router.query, 'mobfilteractive');
  return (
    <>
      {filterObjCopy && (
        <Grid
          h={!isNaN(parseInt(mobFilterActive)) ? '102vh' : '100%'}
          minW={{ base: '100vw', md: 'initial' }}
          maxW={{ base: '390px', md: '390px', lg: '459px' }}
          templateRows={
            !isNaN(parseInt(mobFilterActive)) ? '75px auto 75px' : '75px auto'
          }
          pos={
            !isNaN(parseInt(mobFilterActive))
              ? 'fixed'
              : {
                  base: 'absolute',
                  md: 'sticky',
                }
          }
          top={
            !isNaN(parseInt(mobFilterActive))
              ? '0'
              : { base: '-115px', md: '0' }
          }
          left={
            !isNaN(parseInt(mobFilterActive))
              ? 'auto'
              : { base: '0', md: 'auto' }
          }
        >
          {/* Header */}
          <GridItem
            background={
              !isNaN(parseInt(mobFilterActive))
                ? '#BB3E03'
                : { base: 'none', md: '#BB3E03' }
            }
          >
            {/* //desktop Row1*/}

            <Flex
              p="0 16px"
              display={
                !isNaN(parseInt(mobFilterActive))
                  ? 'flex'
                  : { base: 'none', md: 'flex' }
              }
              justifyContent="space-between"
              alignItems="center"
              h="100%"
              color="white"
              fontFamily="FedraSansStd-book"
            >
              <Box
                fontSize="24px"
                fontWeight="500"
                fontFamily="FedraSansStd-medium"
              >
                {data?.json?.filters ? data.json.filters : 'Filters'}
              </Box>
              <Box
                fontSize="16px"
                cursor="pointer"
                color={isFiltersApplied ? 'white' : 'rgba(255,255,255,0.4)'}
                onClick={
                  isFiltersApplied
                    ? () => {
                        clearFilters();
                        setSearch('');
                        // setCitySearchBox('');
                        setTag(null);
                      }
                    : () => {
                        // throw new Error('error for testing');
                      }
                }
                display={!isNaN(parseInt(mobFilterActive)) ? 'none' : 'block'}
                fontFamily="FedraSansStd-medium"
              >
                {data?.json?.clearAll ? data.json.clearAll : 'Clear All'}
              </Box>
              <Box
                display={!isNaN(parseInt(mobFilterActive)) ? 'block' : 'none'}
                cursor="pointer"
                onClick={() => {
                  setMobFilterActive(null);
                  if (isFiltersApplied) {
                    getFilters(filterObjCopy);
                  }
                }}
                pr="10px"
              >
                <Icon
                  xmlns="http://www.w3.org/2000/svg"
                  width="41px"
                  height="41px"
                  viewBox="0 0 41px 41px"
                  fill="none"
                >
                  <path
                    d="M11.1761 9.5531L32.4069 28.6205L29.845 31.4938L8.61722 12.0715L11.1761 9.5531Z"
                    fill="white"
                  />
                  <path
                    d="M29.9512 9.32422L32.2427 11.9504L11.1769 31.4916L8.53751 28.8827L29.9512 9.32422Z"
                    fill="white"
                  />
                </Icon>
              </Box>
            </Flex>
          </GridItem>

          <Grid
            templateColumns="1fr 2fr"
            display={
              !isNaN(parseInt(mobFilterActive))
                ? 'grid'
                : { base: 'none', md: 'grid' }
            }
          >
            {/* Row1 left list menu */}

            <GridItem
              minW={{ base: '108px', sm: '120px', lg: '153px' }}
              bgColor="#fff"
            >
              <Flex
                fontFamily="FedraSansStd-book"
                flexDir="column"
                gridGap="3px"
                gap="3px"
                h="100%"
                minW={{ base: '108px', sm: '120px', lg: '153px' }}
              >
                {Object.keys(filterObjCopy).map((key, index) => {
                  // console.log(filterObjCopy, '==>');
                  if (Object.keys(data.json)?.includes(key)) {
                    return (
                      <Box pos="relative" key={'filters' + index}>
                        <Flex
                          className={filterActive === index ? 'active' : ''}
                          background={
                            filterActive === index ? '#fff' : '#E4DED4'
                          }
                          fontFamily="FedraSansStd-medium"
                          align="center"
                          h="60px"
                          lineHeight="20px"
                          p="20px 5px 20px 16px"
                          fontSize="14px"
                          fontWeight="500"
                          _before={
                            filterActive === index
                              ? {
                                  borderLeft: '5px solid #BB3E03',
                                  height: { base: '59px', md: '63px' },
                                  pos: 'absolute',
                                  left: { base: '0', md: '-5px' },
                                  bottom: { base: '-3px', md: 'initial' },
                                  zIndex: '1',
                                  content: "' '",
                                  // top: index ? -2 : 0
                                }
                              : {}
                          }
                          cursor="pointer"
                          onClick={() => {
                            setFilterActive(index);
                          }}
                          color="#28231E"
                        >
                          <Box>{data.json[key]}</Box>
                          <Box as="span" color="#BB3E03" pl="5px">
                            {filtersCount?.[`${key}`] &&
                              filtersCount[key] &&
                              ` (${filtersCount[key]})`}
                          </Box>
                        </Flex>
                      </Box>
                    );
                  }
                })}
                <Flex h="100%" background="#E4DED4"></Flex>
              </Flex>
            </GridItem>

            {/* Row1 right accordions */}
            <GridItem
              background="#F8F1E7"
              height="90vh"
              pb={{ base: '90px', md: '0' }}
              // overflowY="auto"
              // className="custom_scroll"
            >
              {/* For Location */}
              <Box
                display={filterActive === 0 ? 'static' : 'none'}
                h="100%"
                maxH="100%"
                className="custom_scroll"
              >
                <Accordion
                  display={filterActive === 0 ? 'static' : 'none'}
                  allowToggle="true"
                  fontFamily="FedraSansStd-book"
                  defaultIndex={[
                    Math.floor(router.query?.activeFilter / 100) == 2
                      ? router.query.activeFilter % 100
                      : -1,
                  ]}
                >
                  {filterObjCopy['location'] &&
                    Object.keys(filterObjCopy['location']).map((key, index) => {
                      let showloc = true;
                      if (
                        key.toLowerCase() == 'state' &&
                        router.asPath.startsWith('/in/en/program-finder')
                      )
                        showloc = true;
                      else if (key.toLowerCase() != 'state') {
                        showloc = true;
                      } else {
                        showloc = false;
                      }
                      if (showloc == true) {
                        return (
                          <AccordionItem
                            key={'location' + index}
                            // _first={{ border: 'none' }}
                            borderTop="none"
                            borderBottom="0.2px solid rgb(0 0 0 / 30%)"
                          >
                            <AccordionButton p="13px 10px 13px 20px">
                              <Box
                                as="span"
                                fontSize="14px"
                                flex="1"
                                textAlign="left"
                              >
                                {data?.json[key]
                                  ? data.json[key]
                                  : 'Key not present in DATO'}{' '}
                                {filters?.slice()?.filter(el => el[key])
                                  ?.length > 0 && (
                                  <Text
                                    as="span"
                                    color="#BB3E03"
                                    fontFamily={{
                                      base: 'FedraSansStd-medium',
                                      md: 'FedraSansStd-book',
                                    }}
                                    fontSize="14px"
                                  >
                                    (
                                    {
                                      filters?.slice()?.filter(el => el[key])
                                        ?.length
                                    }
                                    )
                                  </Text>
                                )}
                                {key === 'center' &&
                                  filters
                                    ?.slice()
                                    ?.filter(el => el['centerName'])?.length >
                                    0 && (
                                    <Text
                                      as="span"
                                      color="#BB3E03"
                                      fontFamily={{
                                        base: 'FedraSansStd-medium',
                                        md: 'FedraSansStd-book',
                                      }}
                                      fontSize="14px"
                                    >
                                      (
                                      {
                                        filters
                                          ?.slice()
                                          ?.filter(el => el['centerName'])
                                          ?.length
                                      }
                                      )
                                    </Text>
                                  )}
                              </Box>
                              <AccordionIcon width="35px" height="35px" />
                            </AccordionButton>

                            <AccordionPanel
                              p="0"
                              bg="white"
                              maxH={{ base: '35vh', md: '65vh' }}
                              overflowY="auto"
                              className="custom_scroll"
                              overflowX="undefined"
                            >
                              <VStack
                                align="flex-start"
                                p={{
                                  base: '0px 14px 0px 14px',
                                  md: '0px 20px 0px 20px',
                                }}
                              >
                                {/* //country textBox */}

                                {key == 'country' && !selectedCountry && (
                                  <Flex pos="relative" w="100%" p="15px 0px 0">
                                    <Flex
                                      borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                      p="0 0 15px 0"
                                      w="100%"
                                    >
                                      <Box pt="10px" pl="0.5rem">
                                        <AiOutlineSearch
                                          size="22px"
                                          cursor="pointer"
                                        />
                                      </Box>
                                      <Input
                                        placeholder="Search country"
                                        // pl="2.2rem"
                                        autoComplete="off"
                                        type="text"
                                        color="#676055"
                                        value={countrySearchBox}
                                        name="country"
                                        focusBorderColor="none"
                                        border="0px solid"
                                        borderRadius="0px"
                                        onChange={handleCountrySearch}
                                        p="11px 11px 11px 8px"
                                      />
                                    </Flex>
                                  </Flex>
                                )}
                                {key == 'country' && selectedCountry && (
                                  <Flex
                                    align="center"
                                    justify="space-between"
                                    w="100%"
                                    p={{
                                      base: '15px 14px 10px 14px',
                                      md: '20px 0 15px 10px',
                                    }}
                                    m="0"
                                    borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                    // bg="#D04723"
                                    // color="white"
                                  >
                                    <Input
                                      type="text"
                                      color="#676055"
                                      value={selectedCountry}
                                      name="country"
                                      focusBorderColor="none"
                                      border="0px solid"
                                      borderRadius="0px"
                                      onChange={e => {
                                        setSelectedCountry(e.target.value);
                                        handleCountrySearch(e);
                                      }}
                                      p="11px 11px 11px 8px"
                                    ></Input>
                                    <Box
                                      marginRight={{ base: '14px', md: '20px' }}
                                      cursor="pointer"
                                      // onClick={handleFilteredCitySelection}
                                      onClick={() => {
                                        setCountrySearchBox('');
                                        setSelectedCountry(null);
                                      }}
                                    >
                                      <AiOutlineClose size="20px" />
                                    </Box>
                                  </Flex>
                                )}

                                {/* //city textBox */}
                                {key == 'city' && !selectedCity && (
                                  <Flex pos="relative" w="100%" p="15px 0px 0">
                                    <Flex
                                      borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                      p="0 0 15px 0"
                                      w="100%"
                                    >
                                      <Box pt="10px" pl="0.5rem">
                                        <AiOutlineSearch
                                          size="22px"
                                          cursor="pointer"
                                        />
                                      </Box>
                                      <Input
                                        placeholder="Search city"
                                        // pl="2.2rem"
                                        autoComplete="off"
                                        type="text"
                                        color="#676055"
                                        value={citySearchBox}
                                        name="city"
                                        focusBorderColor="none"
                                        border="0px solid"
                                        borderRadius="0px"
                                        onChange={handleCitySearch}
                                        p="11px 11px 11px 8px"
                                      />
                                    </Flex>
                                  </Flex>
                                )}
                                {/* city box after filtered city selection */}
                                {key == 'city' && selectedCity && (
                                  <Flex
                                    align="center"
                                    justify="space-between"
                                    w="100%"
                                    p={{
                                      base: '15px 14px 10px 14px',
                                      md: '20px 0 15px 10px',
                                    }}
                                    m="0"
                                    borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                    // bg="#D04723"
                                    // color="white"
                                  >
                                    <Input
                                      type="text"
                                      color="#676055"
                                      value={selectedCity}
                                      name="city"
                                      focusBorderColor="none"
                                      border="0px solid"
                                      borderRadius="0px"
                                      onChange={e => {
                                        setSelectedCity(e.target.value);
                                        handleCitySearch(e);
                                      }}
                                      p="11px 11px 11px 8px"
                                    ></Input>
                                    <Box
                                      marginRight={{ base: '14px', md: '20px' }}
                                      cursor="pointer"
                                      // onClick={handleFilteredCitySelection}
                                      onClick={() => {
                                        setCitySearchBox('');
                                        setSelectedCity(null);
                                      }}
                                    >
                                      <AiOutlineClose size="20px" />
                                    </Box>
                                  </Flex>
                                )}

                                {/* //state textBox */}
                                {key == 'state' && !selectedCity && (
                                  <Flex pos="relative" w="100%" p="15px 0px 0">
                                    <Flex
                                      borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                      p="0 0 15px 0"
                                      w="100%"
                                    >
                                      <Box pt="10px" pl="0.5rem">
                                        <AiOutlineSearch
                                          size="22px"
                                          cursor="pointer"
                                        />
                                      </Box>
                                      <Input
                                        placeholder="Search state"
                                        // pl="2.2rem"
                                        autoComplete="off"
                                        type="text"
                                        color="#676055"
                                        value={stateSearchBox}
                                        name="state"
                                        focusBorderColor="none"
                                        border="0px solid"
                                        borderRadius="0px"
                                        onChange={handleStateSearch}
                                        p="11px 11px 11px 8px"
                                      />
                                    </Flex>
                                  </Flex>
                                )}
                                {/* state box after filtered state selection */}
                                {key == 'state' && selectedState && (
                                  <Flex
                                    align="center"
                                    justify="space-between"
                                    w="100%"
                                    p={{
                                      base: '15px 14px 10px 14px',
                                      md: '20px 0 15px 10px',
                                    }}
                                    m="0"
                                    borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                    // bg="#D04723"
                                    // color="white"
                                  >
                                    <Input
                                      type="text"
                                      color="#676055"
                                      value={selectedState}
                                      name="state"
                                      focusBorderColor="none"
                                      border="0px solid"
                                      borderRadius="0px"
                                      onChange={e => {
                                        setSelectedState(e.target.value);
                                        handleStateSearch(e);
                                      }}
                                      p="11px 11px 11px 8px"
                                    ></Input>
                                    <Box
                                      marginRight={{ base: '14px', md: '20px' }}
                                      cursor="pointer"
                                      // onClick={handleFilteredCitySelection}
                                      onClick={() => {
                                        setStateSearchBox('');
                                        setSelectedState(null);
                                      }}
                                    >
                                      <AiOutlineClose size="20px" />
                                    </Box>
                                  </Flex>
                                )}
                                <Box
                                  // className={
                                  //   key === 'center' ? '' : 'locationCheckHide'
                                  // }
                                  display="flex"
                                  flexDir="column"
                                  // gridGap="20px"
                                  // mt="20px !important"
                                  mt="0 !important"
                                  w="100%"
                                >
                                  {/* dynamic filtered cities  */}
                                  {key == 'country' &&
                                    filterObjCopy['location'][key]
                                      ?.filter(country => {
                                        return Object.keys(country)[0]
                                          ?.toLowerCase()
                                          ?.includes(
                                            debouncedCountryValue?.toLowerCase()
                                          );
                                      })
                                      .map((item, indx) => {
                                        return (
                                          <Box
                                            display="flex"
                                            w="100%"
                                            borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                            key={'country' + indx}
                                          >
                                            <Checkbox
                                              name={key}
                                              colorScheme="green"
                                              borderColor="#B5B5B5"
                                              // background={
                                              //   item?.isChecked
                                              //     ? '#D04723'
                                              //     : 'white'
                                              // }
                                              // color={
                                              //   item?.isChecked
                                              //     ? 'white'
                                              //     : '#28231E'
                                              // }
                                              color="#28231E"
                                              isChecked={
                                                item?.isChecked ? true : false
                                              }
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  filterObjCopy.leftCountryBox =
                                                    countrySearchBox.toLowerCase();
                                                } else {
                                                  delete filterObjCopy.leftCountryBox;
                                                }
                                                handleLocationChange(
                                                  e,
                                                  item,
                                                  filterObjCopy['location'][
                                                    key
                                                  ],
                                                  index
                                                );
                                              }}
                                              w="100%"
                                              p={{
                                                base: '15px 14px 10px 14px',
                                                md: '20px 0 15px 10px',
                                              }}
                                              m="0"
                                            >
                                              <Box> {Object.keys(item)[0]}</Box>
                                            </Checkbox>
                                          </Box>
                                        );
                                      })}
                                  {key == 'city' &&
                                    filterObjCopy['location'][key]
                                      ?.filter(city => {
                                        return city?.city
                                          ?.toLowerCase()
                                          ?.includes(
                                            debouncedValue?.toLowerCase()
                                          );
                                      })
                                      .map((item, indx) => {
                                        return (
                                          <Box
                                            display="flex"
                                            w="100%"
                                            borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                            key={'city' + indx}
                                          >
                                            <Checkbox
                                              name={key}
                                              colorScheme="green"
                                              borderColor="#B5B5B5"
                                              // background={
                                              //   item?.isChecked
                                              //     ? '#D04723'
                                              //     : 'white'
                                              // }
                                              // color={
                                              //   item?.isChecked
                                              //     ? 'white'
                                              //     : '#28231E'
                                              // }
                                              color="#28231E"
                                              isChecked={
                                                item?.isChecked ? true : false
                                              }
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  filterObjCopy.leftCityBox =
                                                    citySearchBox.toLowerCase();
                                                } else {
                                                  delete filterObjCopy.leftCityBox;
                                                }
                                                handleLocationChange(
                                                  e,
                                                  item,
                                                  filterObjCopy['location'][
                                                    key
                                                  ],
                                                  index
                                                );
                                              }}
                                              w="100%"
                                              p={{
                                                base: '15px 14px 10px 14px',
                                                md: '20px 0 15px 10px',
                                              }}
                                              m="0"
                                            >
                                              <Box>{item?.city}</Box>
                                            </Checkbox>
                                          </Box>
                                        );
                                      })}

                                  {/**Dynamic state list */}
                                  {key == 'state' &&
                                    router.asPath.startsWith(
                                      '/in/en/program-finder'
                                    ) &&
                                    filterObjCopy['location'][key]
                                      ?.filter(state => {
                                        return Object.keys(state)[0]
                                          ?.toLowerCase()
                                          ?.includes(
                                            debouncedStateValue?.toLowerCase()
                                          );
                                      })
                                      .map((item, indx) => {
                                        return (
                                          <Box
                                            display="flex"
                                            w="100%"
                                            borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                            key={'state' + indx}
                                          >
                                            <Checkbox
                                              name={key}
                                              colorScheme="green"
                                              borderColor="#B5B5B5"
                                              // background={
                                              //   item?.isChecked
                                              //     ? '#D04723'
                                              //     : 'white'
                                              // }
                                              // color={
                                              //   item?.isChecked
                                              //     ? 'white'
                                              //     : '#28231E'
                                              // }
                                              color="#28231E"
                                              isChecked={
                                                item?.isChecked ? true : false
                                              }
                                              onChange={e => {
                                                if (e.target.checked) {
                                                  filterObjCopy.leftStateBox =
                                                    stateSearchBox.toLowerCase();
                                                } else {
                                                  delete filterObjCopy.leftStateBox;
                                                }
                                                handleLocationChange(
                                                  e,
                                                  item,
                                                  filterObjCopy['location'][
                                                    key
                                                  ],
                                                  index
                                                );
                                              }}
                                              w="100%"
                                              p={{
                                                base: '15px 14px 10px 14px',
                                                md: '20px 0 15px 10px',
                                              }}
                                              m="0"
                                            >
                                              <Box> {Object.keys(item)[0]}</Box>
                                            </Checkbox>
                                          </Box>
                                        );
                                      })}
                                  {filterObjCopy['location'][key]?.map(
                                    (item, indx) => {
                                      if (key == 'city') {
                                        return <></>;
                                      }
                                      if (key == 'state') {
                                        return <></>;
                                      } else if (key == 'center') {
                                        return (
                                          <Box
                                            display="flex"
                                            w="100%"
                                            borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                            key={'center' + indx}
                                          >
                                            <Checkbox
                                              name={key}
                                              borderColor="#B5B5B5"
                                              isChecked={
                                                item?.isChecked ? true : false
                                              }
                                              colorScheme="green"
                                              onChange={e => {
                                                handleLocationChange(
                                                  e,
                                                  item,
                                                  filterObjCopy['location'][
                                                    key
                                                  ],
                                                  index
                                                );
                                              }}
                                              w="100%"
                                              p={{
                                                base: '15px 14px 10px 14px',
                                                md: '20px 0 15px 15px',
                                              }}
                                              m="0"
                                            >
                                              {item?.centerName}
                                            </Checkbox>
                                          </Box>
                                        );
                                      } else if (key == 'country') {
                                        <></>;
                                      }
                                    }
                                  )}
                                </Box>
                              </VStack>
                            </AccordionPanel>
                          </AccordionItem>
                        );
                      }
                    })}
                </Accordion>
                {/* {!filtersCount?.location && (
                  <Button
                    value="nearMe"
                    className="orange-button"
                    mb={'30px'}
                    mx="12px"
                    my="13px"
                    display={'block'}
                    justifyContent="center"
                    alignItems="center"
                    fontWeight={{ base: '350', md: '400' }}
                    fontFamily={'FedraSansStd-book'}
                    fontSize={{ base: '10px', lg: '14px' }}
                    h={'auto'}
                    cursor={'pointer'}
                    textAlign={'center'}
                    padding={'13px 10px'}
                    borderRadius="5px"
                    // color="#faf7f0"
                    textDecoration="none"
                    width={'auto'}
                    maxH="45.2px"
                    rounded="full"
                    _hover={{
                      bg: !userLocation?.browser ? '#DCD5C3' : '#D04723',
                      color: !userLocation?.browser ? '#676055' : '#fff',
                    }}
                    //  isChecked={filterObjCopy?.onlineInperson[0]?.isChecked ? true : false}
                    name="nearMePrograms"
                    backgroundColor={
                      !userLocation?.browser ? '#EBE7DC' : '#D04723'
                    }
                    color={!userLocation?.browser ? '#676055' : '#ffffff'}
                    border="0px"
                    _active={{ border: '0px' }}
                    // style={{
                    //   buttonStyle: 'Program Finder Button',
                    //   buttonText: ' Show Near me',
                    // }}
                    onClick={onLocation}
                  >
                    {data?.json?.showPgmNearMe
                      ? data.json.showPgmNearMe
                      : 'Show Near me'}
                  </Button>
                )} */}
              </Box>

              {/* For ProgramType */}
              <Box
                display={filterActive === 1 ? 'static' : 'none'}
                h="100%"
                maxH="100%"
                className="custom_scroll"
              >
                <Accordion
                  allowToggle
                  fontFamily="FedraSansStd-book"
                  defaultIndex={[
                    Math.floor(router.query?.activeFilter / 100) == 1
                      ? router.query.activeFilter % 100
                      : -1,
                  ]}
                >
                  {filterObjCopy &&
                    filterObjCopy[Object.keys(filterObjCopy)[1]].map(
                      (item, indx) => {
                        if (item['subPrograms']) {
                          return (
                            <AccordionItem
                              key={'progCategory' + indx}
                              // _first={{ border: 'none !important' }}
                              borderTop="none"
                              borderBottom="0.2px solid rgb(0 0 0 / 30%)"
                            >
                              <AccordionButton p="13px 10px 13px 20px">
                                <Box
                                  as="span"
                                  flex="1"
                                  textAlign="left"
                                  fontSize="14px"
                                >
                                  {item['programType'] + ' '}
                                  {getPrograms()
                                    .slice()
                                    .filter(
                                      prog =>
                                        item['programType'] === prog.mainProg
                                    ).length > 0 && (
                                    <Text
                                      as="span"
                                      color="#BB3E03"
                                      fontFamily={{
                                        base: 'FedraSansStd-medium',
                                        md: 'FedraSansStd-book',
                                      }}
                                      fontSize="14px"
                                    >
                                      (
                                      {
                                        getPrograms()
                                          .slice()
                                          .filter(
                                            prog =>
                                              item['programType'] ===
                                              prog.mainProg
                                          ).length
                                      }
                                      )
                                    </Text>
                                  )}
                                </Box>
                                <AccordionIcon width="35px" height="35px" />
                              </AccordionButton>

                              <AccordionPanel
                                p={{
                                  base: '10px 14px 0px 14px',
                                  md: '10px 20px 0px 20px',
                                }}
                                background="white"
                                maxH={{ base: '35vh', md: '65vh' }}
                                overflowY="auto"
                                className="custom_scroll"
                                overflowX="undefined"
                              >
                                <VStack
                                  align="flex-start"
                                  className="checkDesign"
                                >
                                  {item['subPrograms']?.map((item, index) => {
                                    if (item['subPrograms']?.length > 0) {
                                      return (
                                        <Box
                                          borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                          key={'programType' + index}
                                          width="100%"
                                        >
                                          <Accordion
                                            allowMultiple
                                            borderColor="transparent"
                                            outlineColor="transparent"
                                          >
                                            <AccordionItem>
                                              {({ isExpanded }) => (
                                                <>
                                                  <AccordionButton
                                                    borderColor="transparent"
                                                    outlineColor="transparent"
                                                    mb="0px !important"
                                                    // mt={
                                                    //   index
                                                    //     ? '20px'
                                                    //     : '0 !important'
                                                    // }
                                                    // p={index ? '10px 0' : '0'}
                                                    p="10px 0"
                                                    _hover={{}}
                                                  >
                                                    <Text
                                                      as="span"
                                                      width="85%"
                                                      textAlign="left"
                                                    >
                                                      <Checkbox
                                                        colorScheme="green"
                                                        borderColor="#B5B5B5"
                                                        onChange={e => {
                                                          handleProgramTypeChange(
                                                            e,
                                                            true,
                                                            item,
                                                            null,
                                                            indx
                                                          );
                                                        }}
                                                        isChecked={
                                                          item?.isChecked
                                                            ? true
                                                            : false
                                                        }
                                                        fontWeight="bold"
                                                        // w="100%"
                                                        display="flex"
                                                        alignItems="center"
                                                        fontSize="14px !important"
                                                        mb={
                                                          item['subPrograms']
                                                            ?.length > 0
                                                            ? '0'
                                                            : '20px'
                                                        }
                                                      >
                                                        <Text
                                                          display="flex"
                                                          alignItems="center"
                                                          alignSelf="center"
                                                          maxWidth="190px"
                                                        >
                                                          {item['programType']}
                                                        </Text>
                                                      </Checkbox>
                                                    </Text>
                                                    <Flex
                                                      width="15%"
                                                      height="100%"
                                                      alignItems="center"
                                                      justifyContent="center"
                                                    >
                                                      {isExpanded ? (
                                                        <MinusIcon
                                                          fontSize="15px"
                                                          color="#BB3E03"
                                                        />
                                                      ) : (
                                                        <AddIcon
                                                          fontSize="15px"
                                                          color="#BB3E03"
                                                        />
                                                      )}
                                                    </Flex>
                                                  </AccordionButton>
                                                  <AccordionPanel py="0" px="0">
                                                    {item['subPrograms']
                                                      ?.length > 0 && (
                                                      <Box
                                                        p="0 0 0.5rem"
                                                        fontWeight="hairline"
                                                        ml="-15px"
                                                        mt="10px"
                                                      >
                                                        {item[
                                                          'subPrograms'
                                                        ]?.map(
                                                          (subProg, index) => {
                                                            return (
                                                              <Checkbox
                                                                display="flex"
                                                                // alignItems="baseline"
                                                                borderColor="#B5B5B5"
                                                                mb="15px"
                                                                pl="25px"
                                                                colorScheme="green"
                                                                value={
                                                                  subProg[
                                                                    'programType'
                                                                  ]
                                                                }
                                                                onChange={e => {
                                                                  handleProgramTypeChange(
                                                                    e,
                                                                    false,
                                                                    item,
                                                                    subProg,
                                                                    indx
                                                                  );
                                                                }}
                                                                isChecked={
                                                                  subProg?.isChecked
                                                                    ? true
                                                                    : false
                                                                }
                                                                key={
                                                                  'subProg' +
                                                                  index
                                                                }
                                                              >
                                                                {
                                                                  subProg.programType
                                                                }
                                                              </Checkbox>
                                                            );
                                                          }
                                                        )}
                                                      </Box>
                                                    )}
                                                  </AccordionPanel>
                                                </>
                                              )}
                                            </AccordionItem>
                                          </Accordion>
                                        </Box>
                                      );
                                    } else {
                                      return (
                                        <Box
                                          borderBottom="0.2px solid rgb(0 0 0 / 8%)"
                                          key={'programType' + index}
                                          width="100%"
                                          mb="0px !important"
                                          mt={index ? '20px' : '20px'}
                                          p={index ? '10px 0 0' : '0'}
                                        >
                                          <Checkbox
                                            colorScheme="green"
                                            borderColor="#B5B5B5"
                                            onChange={e => {
                                              handleProgramTypeChange(
                                                e,
                                                true,
                                                item,
                                                null,
                                                indx
                                              );
                                            }}
                                            isChecked={
                                              item?.isChecked ? true : false
                                            }
                                            w="100%"
                                            display="flex"
                                            alignItems="start"
                                            fontSize="14px !important"
                                            mb={
                                              item['subPrograms']?.length > 0
                                                ? '0'
                                                : '20px'
                                            }
                                          >
                                            <Box
                                              h="16px"
                                              display="flex"
                                              alignItems="center"
                                              alignSelf="center"
                                            >
                                              {item['programType']}
                                            </Box>
                                          </Checkbox>
                                        </Box>
                                      );
                                    }
                                  })}
                                </VStack>
                              </AccordionPanel>
                            </AccordionItem>
                          );
                        }
                      }
                    )}
                </Accordion>
              </Box>
              {/* For online/inperson */}
              <Box
                bg="#F8F1E7"
                h="100%"
                display={filterActive === 2 ? 'static' : 'none'}
                p="1rem "
              >
                <VStack align="flex-start" className="checkDesign">
                  {filterObjCopy['onlineInperson']?.map((item, index) => {
                    if (data.json?.[`${item?.onlineInperson}`]) {
                      return (
                        <Checkbox
                          key={'onlineInperson' + index}
                          borderColor="#B5B5B5"
                          fontFamily="FedraSansStd-book"
                          mb="20px"
                          isChecked={item?.isChecked ? true : false}
                          onChange={e => {
                            handleOnlineInPersonAndDate(
                              e,
                              item,
                              filterObjCopy['onlineInperson']
                            );
                          }}
                        >
                          {data.json[item?.onlineInperson]}
                        </Checkbox>
                      );
                    }
                  })}
                </VStack>
              </Box>

              {/* For Date */}
              <Box
                bg="#F8F1E7"
                h="100%"
                display={filterActive === 3 ? 'static' : 'none'}
                p="1rem "
              >
                <VStack align="flex-start" className="checkDesign">
                  {filterObjCopy['date']?.map((item, index) => {
                    if (data.json?.[`${item?.date}`]) {
                      return (
                        <Checkbox
                          fontFamily="FedraSansStd-book"
                          borderColor="#B5B5B5"
                          key={'date' + index}
                          name="date"
                          mb="20px !important"
                          isChecked={item?.isChecked ? true : false}
                          onChange={e => {
                            handleOnlineInPersonAndDate(
                              e,
                              item,
                              filterObjCopy[Object.keys(filterObjCopy)[3]]
                            );
                          }}
                        >
                          {data.json[item?.date]}
                        </Checkbox>
                      );
                    }
                  })}
                </VStack>
              </Box>

              {/* For Language */}
              <Box
                bg="#F8F1E7"
                h="100%"
                maxH="100%"
                className="custom_scroll"
                display={filterActive === 4 ? 'static' : 'none'}
              >
                <VStack
                  align="flex-start"
                  className="checkDesign"
                  pb={{ base: '90px', md: '0' }}
                >
                  {Object.keys(filterObjCopy['language'])?.map((key, index) => {
                    return (
                      <Box
                        w="100%"
                        borderTop={
                          index === 0 ? 'none' : '0.2px solid rgb(0 0 0 / 8%) '
                        }
                        mt="0px !important"
                        pt="20px"
                        pl="1.2rem"
                        key={'language' + index}
                      >
                        {Object.keys(filterObjCopy['language'][key]).map(
                          (childKey, index) => {
                            if (childKey == 'isChecked') {
                              return <></>;
                            }
                            return (
                              <Checkbox
                                display="flex"
                                fontFamily="FedraSansStd-book"
                                borderColor="#B5B5B5"
                                mb="20px !important"
                                key={`${key}` + index}
                                isChecked={
                                  filterObjCopy['language'][key][childKey]
                                    ?.isChecked
                                    ? true
                                    : false
                                }
                                onChange={e => {
                                  handleLanguageChange(
                                    e,

                                    childKey,
                                    filterObjCopy['language'][key]
                                  );
                                }}
                              >
                                {childKey}
                              </Checkbox>
                            );
                          }
                        )}
                      </Box>
                    );
                  })}
                </VStack>
              </Box>
              {/* Free Paid */}
              <Box
                bg="#F8F1E7"
                h="100%"
                display={filterActive === 6 ? 'static' : 'none'}
              >
                <Button
                  value="free"
                  mx="12px"
                  my="13px"
                  isChecked={filterObjCopy?.price ? true : false}
                  name="showOnlyFree"
                  className="orange-button"
                  mb={'30px'}
                  display={'block'}
                  justifyContent="center"
                  alignItems="center"
                  fontWeight={{ base: '350', md: '400' }}
                  fontFamily={'FedraSansStd-book'}
                  fontSize={{ base: '10px', lg: '14px' }}
                  h={'auto'}
                  cursor={'pointer'}
                  textAlign={'center'}
                  padding={'13px 10px'}
                  // color="#faf7f0"
                  border="0px"
                  textDecoration="none"
                  width={'auto'}
                  maxH="45.2px"
                  rounded="full"
                  _hover={{
                    bg: !filtersCount?.showOnlyFree ? '#DCD5C3' : '#d04723',
                    color: !filtersCount?.showOnlyFree ? '#676055' : '#fff',
                  }}
                  _active={{ border: '0px' }}
                  backgroundColor={
                    filtersCount?.showOnlyFree ? '#D04723' : '#EBE7DC'
                  }
                  color={filtersCount?.showOnlyFree ? '#ffffff' : '#676055'}
                  onClick={handleFree}
                  py="12px"
                  // style={{
                  //   buttonStyle: 'Program Finder Button',
                  //   buttonText: data?.json?.showOnlyFree
                  //     ? data.json.showOnlyFree
                  //     : ' Show only free',
                  // }}
                >
                  {' '}
                  {data?.json?.showOnlyFree
                    ? data?.json?.showOnlyFree
                    : ' Show only free'}
                </Button>
              </Box>
            </GridItem>
          </Grid>

          <GridItem
            display={!isNaN(parseInt(mobFilterActive)) ? 'block' : 'none'}
            fontFamily="FedraSansStd-book"
            position="fixed"
            bottom="0"
            left="0"
            width="100%"
            background="white"
          >
            <Flex
              fontSize="18px"
              h="75px"
              align="center"
              justify="space-between"
            >
              <Box
                ms="22px"
                onClick={isFiltersApplied ? handleLeftClear : () => {}}
                color={isFiltersApplied ? '#D04723' : 'grey'}
                cursor={isFiltersApplied && 'pointer'}
              >
                {data?.json?.clearAll ? data.json.clearAll : 'Clear All'}
              </Box>
              {!isNaN(parseInt(cardCount)) && filters?.length > 1 ? (
                <Button
                  me="20px"
                  fontWeight="normal"
                  px="30px"
                  py="12.5px"
                  fontFamily="FedraSansStd-medium"
                  onClick={() => {
                    filterObjCopy.scroll = true;
                    getFilters(filterObjCopy);
                    setMobFilterActive(null);
                  }}
                >
                  {/* {data?.json?.showResultsButton.replace('[x]', '')}
                  Apply */}
                  {data?.json?.showResultsButton
                    ? data.json.showResultsButton.replace('[x]', cardCount)
                    : ''}
                  {/* Show {cardCount} results */}
                </Button>
              ) : (
                <Button
                  disabled={true}
                  me="20px"
                  fontWeight="normal"
                  px="30px"
                  py="12.5px"
                  fontFamily="FedraSansStd-medium"
                >
                  {data?.json?.applyButton ? data.json.applyButton : 'Apply'}
                </Button>
              )}
            </Flex>
          </GridItem>
        </Grid>
      )}
      <style jsx global>
        {`
          .chakra-checkbox__control {
            box-shadow: none !important;
          }
          .chakra-checkbox__control::focus {
            box-shadow: none !important;
          }
          .custom_scroll {
            overflow-y: auto;
          }
          .custom_scroll::-webkit-scrollbar {
            width: 12px;
            padding: 0 1px;
          }
          .custom_scroll::-webkit-scrollbar-track {
            background: #eeeeee;
            padding: 0 1px;
          }
          .custom_scroll::-webkit-scrollbar-thumb {
            width: 10px;
            margin: 0 auto;
            background: #cf4520;
          }
          .checkDesign .chakra-checkbox__label {
            font-size: 14px;
          }
          .checkDesign .chakra-checkbox__control {
            border: 1px solid #b5b5b5;
            box-shadow: none;
            width: 18px;
            height: 18px;
          }
          .checkDesign .chakra-checkbox__control[data-checked] {
            border: none;
            background: #5bb018;
            box-shadow: none;
          }
          .checkDesign .chakra-checkbox__control:focus {
            box-shadow: none;
            border: none;
          }
          .checkDesign input[type='checkbox'] {
            border: 1px solid #000;
          }
          .locationCheckHide .chakra-checkbox__control {
            display: none;
          }
        `}
      </style>
    </>
  );
};

export default FilterAside;
